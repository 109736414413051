import React from 'react'
import { Box, Typography, Link } from '@mui/material'
import Ketch from 'assets/icons/onboarding/ketch.png'
import { Button, theme, Spinner } from '@ketch-com/deck'
import { FormInput } from 'components/form/FormInput'
import { useIpLocation } from 'api/externalApis/ketchCdn/queries/useIpLocation'
import { SignUpFormFields } from 'pages/onboarding/signUp/common/types/signUp'
import { useFormikContext } from 'formik'
import { FormCheckbox } from 'components/form/FormCheckbox'

const US_AUS_COUNTRY_CODES = ['US', 'AU']
const PRIVACY_POLICY_LINK = 'https://www.ketch.com/privacy-policy'
const UNSUBSCRIBE_LINK = 'https://content.ketch.com/hs/manage-preferences/unsubscribe-simple?via_redirect=true'
const TERMS_OF_SERVICE_LINK = 'https://www.ketch.com/terms-of-service'
const GOOGLE_PRIVACY = 'https://policies.google.com/privacy'
const GOOGLE_TERMS = 'https://policies.google.com/terms'

export const SignUpCompleteForm: React.FC = () => {
  // Determine if the user is in the EU
  const { data: ipLocation, isLoading: isLoadingIpLocation } = useIpLocation({})
  const isUSOrAU = !!ipLocation?.location?.countryCode && US_AUS_COUNTRY_CODES.includes(ipLocation.location.countryCode)
  const { handleSubmit, setFieldTouched, isValid, isSubmitting } = useFormikContext<SignUpFormFields>()

  if (isLoadingIpLocation || isSubmitting)
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Spinner size="30px" thickness={3} />
      </Box>
    )

  return (
    <Box display="flex" justifyContent="center" px={2}>
      <Box flexBasis={420} display="flex" flexDirection="column" gap={6}>
        <Box width={194} height={66}>
          <img src={Ketch} alt="KetchLogo" style={{ width: '100%', height: 'auto' }} />
        </Box>

        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h1">Complete your profile</Typography>
            <Typography variant="body">Personalize your experience at Ketch.</Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={3}>
            <FormInput
              fullWidth
              required
              label="Company / Organization Name"
              formPropertyName="company"
              placeholder="Ketch"
              debounceWaitTime={1000}
              disabled={isSubmitting}
            />
            <FormInput
              fullWidth
              required
              label="Website Link"
              formPropertyName="website"
              placeholder="https://www.ketch.com"
              debounceWaitTime={1000}
              disabled={isSubmitting}
            />
            <FormInput
              required
              fullWidth
              label={
                <Box display="flex" alignItems="center" gap={0.5}>
                  <Typography variant="label">Privacy Policy Link</Typography>
                  <Typography variant="label" color={theme.palette.Text.Secondary}>
                    (Optional)
                  </Typography>
                </Box>
              }
              formPropertyName="privacyLink"
              placeholder=""
              debounceWaitTime={1000}
              disabled={isSubmitting}
            />
            <Box mt={1}>
              <Button
                disabled={isSubmitting}
                size="large"
                fullWidth
                color="primary"
                onClick={() => {
                  setFieldTouched('company')
                  setFieldTouched('website')
                  if (isValid) handleSubmit()
                }}
                sx={{
                  backgroundColor: `${theme.palette.Success.Primary} !important`,
                }}
              >
                Create Account
              </Button>
            </Box>
          </Box>

          <Box>
            {/* Display consent and disclosures depending on country code */}
            {isUSOrAU ? (
              <Box display="flex">
                <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                  By clicking 'Create Account' you agree to Ketch's{' '}
                  <Link href={TERMS_OF_SERVICE_LINK} rel="noopener noreferrer" target="_blank">
                    Terms of Service
                  </Link>
                  , the receipt of the requested information as well as sales and marketing communications related to
                  Ketch products, services, and events. You can{' '}
                  <Link href={UNSUBSCRIBE_LINK} rel="noopener noreferrer" target="_blank">
                    unsubscribe
                  </Link>{' '}
                  at any time in accordance with our{' '}
                  <Link href={PRIVACY_POLICY_LINK} rel="noopener noreferrer" target="_blank">
                    Privacy Policy
                  </Link>
                  .
                </Typography>
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box display="flex" alignItems="center" gap={1}>
                  <FormCheckbox disabled={isSubmitting} name="marketingConsent" />
                  <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                    Check this box to receive sales and marketing communications related to Ketch products, services,
                    and events. You can{' '}
                    <Link href={UNSUBSCRIBE_LINK} rel="noopener noreferrer" target="_blank">
                      unsubscribe
                    </Link>{' '}
                    any time in accordance with our{' '}
                    <Link href={PRIVACY_POLICY_LINK} rel="noopener noreferrer" target="_blank">
                      Privacy Policy
                    </Link>
                    .
                  </Typography>
                </Box>
                <Box display="flex">
                  <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                    By clicking 'Create Account' you agree to Ketch's{' '}
                    <Link href={TERMS_OF_SERVICE_LINK} rel="noopener noreferrer" target="_blank">
                      Terms of Service
                    </Link>{' '}
                    and the receipt of communications associated with the creation and management of your Ketch Free
                    account.
                  </Typography>
                </Box>
              </Box>
            )}

            <Box display="flex" mt={1}>
              <Typography variant="smallBody" color={theme.palette.Text.Secondary}>
                This site is protected by reCAPTCHA and the Google{' '}
                <Link href={GOOGLE_PRIVACY} rel="noopener noreferrer" target="_blank">
                  Privacy Policy
                </Link>{' '}
                and{' '}
                <Link href={GOOGLE_TERMS} rel="noopener noreferrer" target="_blank">
                  Terms of Service
                </Link>{' '}
                apply.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
