import { useMemo } from 'react'
import * as Yup from 'yup'
import { validationRegExp } from 'utils/constants/forms/validationRegExp'
import { validationMessages } from 'utils/constants/forms/validationMessages'
import { useCheckIsOrganizationCodeAvailable } from 'api/organizations/queries/useCheckIsOrganizationCodeAvailable'
import { useIsAvailableHandler } from 'utils/helpers/validators/useIsAvailableHandler'
import { PrivacyPolicyLinkPattern } from 'utils/validation/privacyPolicyLink'
import { PrivacyPolicyLinkPrefixPattern } from 'utils/validation/privacyPolicyLinkPrefix'
import { URLSchemaPattern } from 'utils/validation/urlSchema'

const nonCompanyEmailDomains = ['gmail.com', 'yahoo.com', 'mail.ru', 'yandex.mail']

const bannedEmailDomains = [
  'transcend.io',
  'securiti.ai',
  'bigid.com',
  'datagrail.io',
  'didomi.io',
  'onetrust.com',
  'osano.com',
  'truyo.com',
  'wirewheel.io',
  'trustarc.com',
  'cookiebot.com',
  'secuvy.ai',
  'sourcepoint.com',
  'eligrey.com',
]

export const useValidationSchema = () => {
  const { mutateAsync: handleCheckOrganizationCodeAvailable } = useCheckIsOrganizationCodeAvailable()

  const handleCheckIsOrganizationCodeAvailable = useIsAvailableHandler({
    handler: value =>
      handleCheckOrganizationCodeAvailable({
        params: {
          code: value,
        },
      }),
  })

  return useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().required(validationMessages.SIGNUP_REQUIRED),
        email: Yup.string()
          .email(validationMessages.SIGNUP_VALID_EMAIL)
          .required(validationMessages.SIGNUP_REQUIRED)
          .max(254, validationMessages.MAX_CHARS_EMAIL)
          .test('non-company-domain-check', validationMessages.NON_COMPANY_EMAIL, value => {
            if (!value) return true
            const domain = value.split('@')[1]
            return !nonCompanyEmailDomains.includes(domain)
          })
          .test('banned-domain-check', validationMessages.BANNED_EMAIL, value => {
            if (!value) return true
            const domain = value.split('@')[1]
            return !bannedEmailDomains.includes(domain)
          }),
        company: Yup.string()
          .matches(validationRegExp.MIN_THREE_CHARS, validationMessages.MIN_THREE_CHARS)
          .matches(validationRegExp.MAX_FIFTY_CHARS, validationMessages.MAX_FIFTY_CHARS)
          .matches(
            validationRegExp.ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE,
            validationMessages.ONLY_LOWERCASE_CHARS_NUMBERS_AND_UNDERSCORE,
          )
          .matches(validationRegExp.NO_SPACES, validationMessages.NO_SPACES)
          .test('Is Unique', validationMessages.MUST_BE_UNIQUE, handleCheckIsOrganizationCodeAvailable)
          .required(validationMessages.SIGNUP_REQUIRED),
        website: Yup.string()
          .required(validationMessages.SIGNUP_REQUIRED)
          .max(500, validationMessages.MAX_CHARS_WEBSITE)
          .matches(URLSchemaPattern, validationMessages.VALID_WEBSITE),
        privacyLink: Yup.string()
          .max(500, validationMessages.MAX_CHARS_PRIVACY_POLICY)
          .matches(PrivacyPolicyLinkPrefixPattern, validationMessages.HTTP_PRIVACY_POLICY)
          .matches(PrivacyPolicyLinkPattern, validationMessages.VALID_PRIVACY_POLICY),
      }),
    [handleCheckIsOrganizationCodeAvailable],
  )
}
