import { SyntheticEvent, useEffect, useMemo, useState } from 'react'
import { useLocation, Routes, Route, useNavigate } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { Box, Typography } from '@mui/material'
import { Connections } from '../components/Connections/Connections'
import { DataSystemWidgets } from './components/Widgets/DataSystemWidgets'
import { Avatar, AvatarSize, Chip, Spinner, ViewLayout, theme } from '@ketch-com/deck'
import { Capabilites } from '../components/Capabilities'
import { showToast } from 'components/modals/AlertComponent'
import { Logger } from 'utils/Logger'
import { AppActions } from 'pages/dataSystems/DataSystem/components/AppActions'
import { Overview } from 'pages/dataSystems/DataSystem/components/Overview'
import { Details } from 'pages/dataSystems/DataSystem/components/Details'
import { useDataSystemsContext } from './context/DataSystemContext'
import { RiskManagement } from './components/RiskManagementTab'
import { useIsEntitled } from 'utils/hooks'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'

export const DataSystem = () => {
  const navigate = useNavigate()

  const { isEntitled } = useIsEntitled()
  const isEntitledToDemo = isEntitled(ENTITLEMENTS.DEMO)

  const location = useLocation()
  const { search } = location

  const { appDescriptor, isLoading, isError } = useDataSystemsContext()

  const [currentTab, setCurrentTab] = useState(0)
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue)
  }

  let newTabs = useMemo(() => {
    const overviewTab = {
      label: 'Overview',
      link: {
        pathname: relativePath(RoutesManager.systems.id.overview.root.pattern),
        search,
      },
    }

    const riskManagementTab = {
      label: 'Risk Management',
      link: {
        pathname: `${relativePath(RoutesManager.systems.id.riskManagement.root.pattern)}`,
        search,
      },
    }

    if (!appDescriptor?.dataSystem?.appCode) {
      if (isEntitledToDemo) return [overviewTab, riskManagementTab]
      return [overviewTab]
    }
    const tabs = [
      overviewTab,
      {
        label: (
          <Typography variant="label">
            Connections{' '}
            <Typography variant="label" color={currentTab === 1 ? 'darkDusk.main' : 'fadedDarkGrey.main'}>
              {appDescriptor.connections?.length ?? 0}
            </Typography>
          </Typography>
        ),
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.connections.root.pattern)}`,
          search,
        },
      },
      {
        label: 'Integration Details',
        link: {
          pathname: `${relativePath(RoutesManager.systems.id.details.root.pattern)}`,
          search,
        },
      },
    ]
    if (isEntitledToDemo) {
      tabs.push(riskManagementTab)
    }
    return tabs
  }, [search, appDescriptor?.dataSystem?.appCode, appDescriptor?.connections?.length, currentTab, isEntitledToDemo])

  useEffect(() => {
    navigate(newTabs[currentTab].link)
  }, [currentTab, navigate, newTabs])

  if (isLoading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flex={1}>
        <Spinner size="32px" thickness={2.5} />
      </Box>
    )
  } else if (isError || !appDescriptor) {
    Logger.error('Data Systems: Error fetching Installed Data System')
    return null
  } else if (!appDescriptor?.dataSystem) {
    Logger.error('Installed Data System missing Data System')
    showToast({ content: 'Internal Server Error. Please contact support', type: 'error' })
    return null
  }

  const hasCredentialsIssue = appDescriptor.connections?.some(connection => {
    return connection.hasCredentialsIssue === true
  })

  return (
    <>
      <ViewLayout
        contentGroupProps={{
          title: appDescriptor.dataSystem?.name,
          showBackButton: true,
          propsBackButton: {
            onClick: () => navigate(RoutesManager.systems.systems.root.getURL()),
          },
          imageBlockComponent: (
            <Avatar
              isLogo
              src={appDescriptor.dataSystem?.logoUrl}
              alt={appDescriptor.dataSystem?.logoUrl}
              variant="rounded"
              size={AvatarSize['2xlarge']}
              backgroundColor={theme.palette.tertiary.main}
            />
          ),
          infoBlockComponent: (
            <Box display="flex" alignItems="center" gap={1} flexWrap={'wrap'}>
              <Chip
                label={appDescriptor?.type}
                size="small"
                sx={{
                  textTransform: 'capitalize',
                }}
              />
              <Capabilites
                isShortVersion
                hasNoConnections={!appDescriptor.connections?.length}
                hasCredentialsIssue={hasCredentialsIssue}
                supportedCapabilites={appDescriptor.dataSystem?.supportedCapabilities!}
              />
            </Box>
          ),
          actionRightBlockComponent: <AppActions appDescriptor={appDescriptor} />,
        }}
        tabsComponentProps={{ value: currentTab, onChange: handleChange, tabItems: newTabs }}
        widgetContentComponent={<DataSystemWidgets />}
      >
        <Routes location={location}>
          <Route path="/" element={<Overview appDescriptor={appDescriptor} />} />

          <Route
            path={RoutesManager.systems.id.overview.root.pattern}
            element={<Overview appDescriptor={appDescriptor} />}
          />

          <Route
            path={RoutesManager.systems.id.connections.root.pattern}
            element={<Connections appDescriptor={appDescriptor} backgroundColor={theme.palette.superLightGrey.main} />}
          />

          <Route
            path={RoutesManager.systems.id.details.root.pattern}
            element={<Details appDescriptor={appDescriptor} />}
          />
          <Route path={RoutesManager.systems.id.riskManagement.root.pattern} element={<RiskManagement />} />
        </Routes>
      </ViewLayout>
    </>
  )
}
