export const onboarding = {
  signUp: {
    root: {
      pattern: '/sign-up/*',
      getURL: () => '/sign-up',
    },
    begin: {
      pattern: '/begin',
      getURL: () => '/sign-up/begin',
    },
    complete: {
      pattern: '/complete',
      getURL: () => '/sign-up/complete',
    },
    completed: {
      pattern: '/completed',
      getURL: () => '/sign-up/completed',
    },
  },
}
