import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { SignUpFormInsights } from 'pages/onboarding/signUp/components/SignUpFormInsights'
import { SignUpFormCompletedInsights } from 'pages/onboarding/signUp/components/SignUpFormCompletedInsights'

export const SignUpInsights: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SignUpFormInsights />} />
      <Route
        path={relativePath(RoutesManager.onboarding.signUp.begin.pattern, RoutesManager.onboarding.signUp.root.pattern)}
        element={<SignUpFormInsights />}
      />
      <Route
        path={relativePath(
          RoutesManager.onboarding.signUp.complete.pattern,
          RoutesManager.onboarding.signUp.root.pattern,
        )}
        element={<SignUpFormInsights />}
      />
      <Route
        path={relativePath(
          RoutesManager.onboarding.signUp.completed.pattern,
          RoutesManager.onboarding.signUp.root.pattern,
        )}
        element={<SignUpFormCompletedInsights />}
      />
    </Routes>
  )
}
