import React from 'react'
import { Grid, Box } from '@mui/material'
import { ReactComponent as SignUpTitle } from 'assets/icons/onboarding/title.svg'
import { ReactComponent as SignUpBadges } from 'assets/icons/onboarding/badges.svg'
import SignUpHeroImage from 'assets/icons/onboarding/signup-hero.png'
import ChipotleLogo from 'assets/icons/onboarding/chipotle.png'
import ArbysLogo from 'assets/icons/onboarding/arbys.png'
import ForbesLogo from 'assets/icons/onboarding/forbes.png'
import GrindrLogo from 'assets/icons/onboarding/grindr.png'
import PurpleLogo from 'assets/icons/onboarding/purple.png'
import SeatGeekLogo from 'assets/icons/onboarding/seatgeek.png'
import TimeLogo from 'assets/icons/onboarding/time.png'
import { theme } from '@ketch-com/deck'

export const SignUpFormInsights: React.FC = () => {
  return (
    <Grid container flexDirection="column" gap={8} py={6} bgcolor={theme.palette.Black.o8}>
      <Grid container>
        <Grid item lg={4} md={12}>
          <Box display="flex" flexDirection="column" gap={6} minWidth={280}>
            <Box width={240} ml={3}>
              <SignUpTitle aria-label="2500+ businesses streamline their privacy program with Ketch" />
            </Box>
            <Box width={240} ml={3}>
              <SignUpBadges />
            </Box>
          </Box>
        </Grid>
        <Grid item lg={8} display={{ sm: 'none', lg: 'block' }}>
          <img
            src={SignUpHeroImage}
            alt=""
            style={{ width: '100%', height: 'auto', position: 'relative', top: '-48px', right: '-60px' }}
          />
        </Grid>
      </Grid>
      <Grid container flexWrap="wrap" justifyContent="center">
        <Box width={100} height={50}>
          <img src={ArbysLogo} alt="ArbysLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
        <Box width={100} height={50}>
          <img src={TimeLogo} alt="TimeLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
        <Box width={100} height={50}>
          <img src={GrindrLogo} alt="GrindrLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
        <Box width={100} height={50}>
          <img src={ChipotleLogo} alt="ChipotleLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
        <Box width={100} height={50}>
          <img src={ForbesLogo} alt="ForbesLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
        <Box width={100} height={50}>
          <img src={SeatGeekLogo} alt="SeatGeekLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
        <Box width={100} height={50}>
          <img src={PurpleLogo} alt="PurpleLogo" style={{ width: '100%', height: 'auto', opacity: 0.5 }} />
        </Box>
      </Grid>
    </Grid>
  )
}
