export enum Release {
  PolicyUI = 'policy-ui',
  DataSystems = 'data-systems',
  RightsQueueManualIntervention = 'rights-queue-manual-intervention',
  NewNavigation = 'Navigation 2.0',
  TeamNotifications = 'team-notifications',
  FabButton = 'fab-button',
  PropertyThemeDocuments = 'property-theme-documents',
  FormsWithinExperienceServer = 'forms-within-experience-server',
  TransponderCreationFlow = 'transponder-creation-flow',
  PropertyTagsTrackerBlocking = 'property-tags-and-tracker-blocking',
}

export const RELEASES: Release[] = [
  Release.PolicyUI,
  Release.DataSystems,
  Release.RightsQueueManualIntervention,
  Release.NewNavigation,
  Release.TeamNotifications,
  Release.FabButton,
  Release.PropertyThemeDocuments,
  Release.FormsWithinExperienceServer,
  Release.TransponderCreationFlow,
  Release.PropertyTagsTrackerBlocking,
]
