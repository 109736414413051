import { useInfiniteQuery } from 'react-query'
import { useEffect, useState } from 'react'
import { FilterDTO, InstalledDataSystemV2ListItemDTO } from '@ketch-com/figurehead'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { fetchInstalledDataSystemsV2List } from '../fetchers/fetchInstalledDataSystemsV2List'

export type FilterCategories = 'ownerUserIDs' | 'personalDataType' | 'assignedCategories' | 'type'
// Full filters list isn't supported yet
// export type FilterCategories = 'ownerUserIDs' | 'personalDataType' | 'assignedCategories' | 'type' | 'dataResidencies' | 'securityMeasures';

export interface UseInstalledDataSystemsV2ListInfiniteParams extends Record<FilterCategories | 'name', FilterDTO> {}

// assignedCategories aka dataCategories and DataResidencies are currently unsupported
export const useInstalledDataSystemsV2ListInfinite = ({
  ownerUserIDs,
  personalDataType,
  assignedCategories,
  type,
  // dataResidencies,
  // securityMeasures,
  name,
}: UseInstalledDataSystemsV2ListInfiniteParams) => {
  const [allDataSystems, setAllDataSystems] = useState<InstalledDataSystemV2ListItemDTO[]>([])
  const [totalResults, setTotalResults] = useState<number>(0)
  const limit = 50

  const fetchInstalledDataSystems = async (pageParam: number) => {
    const filters = [
      ownerUserIDs,
      personalDataType,
      assignedCategories,
      type,
      name,
      // dataResidencies,
      // securityMeasures,
    ].filter(filter => filter.values?.length) // Only include filters with values

    const fetchInstalledDataSystemsResult = await fetchInstalledDataSystemsV2List({
      pagination: { limit, offset: (pageParam - 1) * limit },
      filters,
    })
    return {
      dataSystems: fetchInstalledDataSystemsResult.data.installedDataSystems ?? [],
      totalResults: ((fetchInstalledDataSystemsResult.data.pagination as any)?.total_count as number) ?? 0,
      pageParam,
    }
  }

  const infiniteQuery = useInfiniteQuery(
    [
      ApiQueryKeys.dataSystemsList,
      ownerUserIDs,
      personalDataType,
      assignedCategories,
      type,
      // dataResidencies,
      // securityMeasures,
      name,
    ],
    ({ pageParam = 1 }) => fetchInstalledDataSystems(pageParam),
    {
      getNextPageParam: lastPage => {
        return lastPage.pageParam * limit < lastPage.totalResults ? lastPage.pageParam + 1 : undefined
      },
      keepPreviousData: true,
    },
  )

  useEffect(() => {
    if (infiniteQuery.isFetching || infiniteQuery.isLoading) return

    let allData: InstalledDataSystemV2ListItemDTO[] = []
    let totalResults = 0

    if (infiniteQuery.data?.pages) {
      infiniteQuery.data.pages.forEach(page => {
        allData = [...allData, ...page.dataSystems]
        totalResults = page.totalResults
      })
      setAllDataSystems(allData)
      setTotalResults(totalResults)
    }
  }, [infiniteQuery.data, infiniteQuery.isFetching, infiniteQuery.isLoading])

  return {
    ...infiniteQuery,
    data: allDataSystems,
    totalResults,
  }
}
