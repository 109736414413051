import React from 'react'
import { Box, Typography } from '@mui/material'
import Ketch from 'assets/icons/onboarding/ketch.png'

export const SignUpCompleted: React.FC = () => {
  return (
    <Box display="flex" justifyContent="center" px={2}>
      <Box flexBasis={420} display="flex" flexDirection="column" gap={6}>
        <Box width={194} height={66}>
          <img src={Ketch} alt="KetchLogo" style={{ width: '100%', height: 'auto' }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="h1">Almost done!</Typography>
            <Typography variant="body">
              Please check your email and click the verification link we sent you to confirm your account.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
