import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { IDParams } from './utils'

// TODO:JB - Rename to dataMap once released to prod
export const systems = {
  root: {
    pattern: '/data-map',
    getURL: () => `/data-map`,
    permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
  },

  // Systems home
  systemsHome: {
    root: {
      pattern: '/systems-home',
      getURL: () => '/data-map/systems-home',
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
  },

  // Data system
  id: {
    root: {
      pattern: '/systems-home/systems/:id',
      getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    overview: {
      root: {
        pattern: '/overview',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/overview`,
      },
    },
    connections: {
      root: {
        pattern: '/connections',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/connections`,
      },
    },
    details: {
      root: {
        pattern: '/advanced',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/details`,
      },
    },
    riskManagement: {
      root: {
        pattern: '/risk-management',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/risk-management`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
  },

  // New
  systems: {
    root: {
      pattern: '/systems',
      getURL: () => '/data-map/systems-home/systems',
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    settings: {
      root: {
        pattern: '/systems-home/systems/settings',
        getURL: () => '/data-map/systems-home/systems/settings',
        permissions: [PERMISSIONS.DATA_SYSTEMS_WRITE],
      },
    },
    id: {
      root: {
        pattern: '/systems-home/systems/:id',
        getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
      edit: {
        root: {
          pattern: '/:id/edit',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/edit`,
        },
      },
      overview: {
        root: {
          pattern: '/systems-home/systems/:id/overview',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/overview`,
        },
      },
      connections: {
        root: {
          pattern: '/connections',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/connections`,
        },
      },
      details: {
        root: {
          pattern: '/advanced',
          getURL: ({ id }: IDParams) => `/data-map/systems-home/systems/${id}/details`,
        },
      },
    },
  },

  connections: {
    root: {
      pattern: '/connections',
      getURL: () => `/data-map/systems-home/connections`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    create: {
      pattern: '/systems-home/connections/create',
      getURL: () => `/data-map/systems-home/connections/create`,
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
    upsert: {
      root: {
        pattern: '/systems-home/connections/:id/upsert/instance/:instanceId',
        getURL: ({ id, instanceId }: { id?: string; instanceId?: string }) =>
          `/data-map/systems-home/connections/${id}/upsert/instance/${instanceId}`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
      edit: {
        pattern: '/systems-home/connections/:id/upsert/edit/instance/:instanceId',
        getURL: ({ id, instanceId }: { id?: string; instanceId?: string }) =>
          `/data-map/systems-home/connections/${id}/upsert/edit/instance/${instanceId}`,
        permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
      },
    },
  },

  create: {
    root: {
      pattern: '/systems-home/add-system',
      getURL: () => '/data-map/systems-home/add-system',
      permissions: [PERMISSIONS.DATA_SYSTEMS_READ],
    },
  },

  labels: {
    root: {
      pattern: '/data-map/labels',
      getURL: () => '/data-map/labels',
      permissions: [PERMISSIONS.LABEL_READ],
    },
    classifications: {
      pattern: `/data-map/labels/classifications`,
      getURL: () => `/data-map/labels/classifications`,
      permissions: [PERMISSIONS.LABEL_READ],
    },
    dataCategories: {
      pattern: `/data-map/labels/data-categories`,
      getURL: () => `/data-map/labels/data-categories`,
      permissions: [PERMISSIONS.LABEL_READ],
    },
  },
}
