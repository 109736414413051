import { DataSystemWidgetsContext, withDataSystemWidgetsContext } from './context/DataSystemWidgetsContext'
import { DataCategoriesWidget } from './components/DataCategoriesWidget/DataCategoriesWidget'
import React, { useContext } from 'react'
import { Box, Divider, styled } from '@mui/material'
import { PurposesWidget } from './components/PurposesWidget'
import { ProcessingActivitiesWidget } from './components/ProcessingActivitiesWidget'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { useDataSystemsContext } from '../../context/DataSystemContext'
import { SystemProfileStatusWidget } from './components/SystemProfileStatusWidget'
import { DemoAssessmentInDataSystemWidget } from 'pages/policyCenter/processingActivities/view/components/widgets/DemoAssesmentInDataSystemWidget'

const WidgetsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  marginBottom: 32,
}))

const DataSystemWidgetsWithContext: React.FC = () => {
  const { isEntitled } = useContext(DataSystemWidgetsContext)
  const entitledToDataMap = isEntitled(ENTITLEMENTS.DATA_MAPPING)
  const entitledToDemo = isEntitled(ENTITLEMENTS.DEMO)
  const { appDescriptor } = useDataSystemsContext()
  const displayProgressWidget =
    appDescriptor?.progress?.filled !== undefined &&
    appDescriptor.progress.total &&
    appDescriptor.progress.filled !== appDescriptor.progress.total

  return (
    <WidgetsContainer>
      {displayProgressWidget && (
        <>
          <SystemProfileStatusWidget />
          <Divider />
        </>
      )}
      <DataCategoriesWidget />
      {entitledToDemo && <DemoAssessmentInDataSystemWidget />}
      {entitledToDataMap && (
        <>
          <PurposesWidget />
          <ProcessingActivitiesWidget />
        </>
      )}
    </WidgetsContainer>
  )
}

export const DataSystemWidgets = withDataSystemWidgetsContext(DataSystemWidgetsWithContext)
