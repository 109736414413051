import React from 'react'
import { Grid } from '@mui/material'
import { theme } from '@ketch-com/deck'
import { SignUpInsights } from 'pages/onboarding/signUp/components/SignUpInsights'
import { SignUpActions } from 'pages/onboarding/signUp/components/SignUpActions'
import { Formik } from 'formik'
import { useSignUp } from 'pages/onboarding/signUp/common/hooks/useSignUp'
import { useLocation } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'

export const SignUp: React.FC = () => {
  const location = useLocation()
  const { validationSchema, initialValues, onSignUpFormSubmit } = useSignUp()
  const isSignUpCompleted = location.pathname === RoutesManager.onboarding.signUp.completed.getURL()
  return (
    <Grid container bgcolor={theme.palette.white.main} minHeight="100vh" justifyContent="stretch">
      <Grid
        item
        display={{ xs: 'none', sm: 'flex' }}
        maxWidth={isSignUpCompleted ? '35% !important' : '900px !important'}
        sm={6}
      >
        <SignUpInsights />
      </Grid>
      <Grid item xs={12} sm={6} md={6} py={6}>
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={values => {
            onSignUpFormSubmit(values)
          }}
        >
          <SignUpActions />
        </Formik>
      </Grid>
    </Grid>
  )
}
