import { InfoRow } from '@ketch-com/deck'
import { InstalledDataSystemRelationshipTypeV2DTO, InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import TextAreaInfoRow from 'components/InfoRow/TextAreaInfoRow'
import ChipContentRadioEditInfoRow from 'components/InfoRow/ChipContentRadioEditInfoRow'
import {
  DocumentOptionType,
  PersonalDataTypeOptions,
  TypeFilterOptions,
  TypeOptions,
} from 'pages/dataSystems/DataSystem/types'
import ContactEditInfoRow from 'pages/dataSystems/DataSystem/components/ContactEditInfoRow'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import DataResidencyWithEdit from 'pages/dataSystems/DataSystem/components/DataResidencyWithEdit'
import ChipContentOwnerDropdownEditInfoRow from './ChipContentOwnerDropdownEditInfoRow'
import ChipContentTeamDropdownEditInfoRow from './ChipContentTeamDropdownEditInfoRow'
import ChipContentDocDropdownEditInfoRow from './ChipContentDocDropdownEditInfoRow'
import { useDataSystemsContext } from '../context/DataSystemContext'

export type PredeterminedFieldsProps = {
  appDescriptor: InstalledDataSystemV2DTO
}

export default function PredeterminedFields({ appDescriptor: appDescriptorProp }: PredeterminedFieldsProps) {
  const {
    regionResponse,
    updatedDataSystem,
    allUserOptions,
    systemUsers,
    userOwners,
    allTeamOptions,
    docsUsed,
    allDocuments,
    handleSystemFieldUpdates: handleUpdates,
  } = useDataSystemsContext()

  return (
    <>
      <TextAreaInfoRow
        title={'Description'}
        value={updatedDataSystem?.description}
        setValue={t => handleUpdates({ description: t })}
      />
      <ChipContentOwnerDropdownEditInfoRow
        title="System Owner"
        options={allUserOptions}
        values={userOwners}
        setValues={(values: string[] | undefined) => handleUpdates({ ownerUserIds: values })}
      />
      <ChipContentTeamDropdownEditInfoRow
        title="System Users"
        options={allUserOptions}
        values={systemUsers}
        setValues={(values: string[] | undefined) => handleUpdates({ systemUserIds: values })}
      />
      <ContactEditInfoRow
        title="System Contact"
        value={updatedDataSystem?.contact}
        setValue={value => handleUpdates({ contact: value })}
      />
      <ChipContentRadioEditInfoRow
        allowDelete={false}
        title="System Type"
        options={TypeOptions}
        chipOptions={TypeOptions}
        radioOptions={TypeFilterOptions}
        value={TypeOptions[updatedDataSystem ? updatedDataSystem.type! : appDescriptorProp.type!].value}
        setValue={value => handleUpdates({ type: value as InstalledDataSystemRelationshipTypeV2DTO })}
      />
      <ChipContentRadioEditInfoRow
        allowDelete={false}
        title="Data Sensitivity"
        options={PersonalDataTypeOptions}
        value={
          PersonalDataTypeOptions[
            updatedDataSystem ? updatedDataSystem.personalDataType! : appDescriptorProp.personalDataType!
          ].value
        }
        setValue={value => handleUpdates({ personalDataType: value as InstalledDataSystemRelationshipTypeV2DTO })}
      />
      <ChipContentDocDropdownEditInfoRow
        title={'Documents'}
        allDocuments={allDocuments}
        setValues={value => handleUpdates({ documents: value })}
        values={docsUsed as DocumentOptionType[]}
      />
      <DataResidencyWithEdit
        title="Data Residencies"
        setValues={values => handleUpdates({ residencies: values })}
        values={updatedDataSystem?.residencies || []}
        regionResponse={regionResponse}
      />
      <InfoRow title="Last Updated">
        <FormattedDateRenderer date={updatedDataSystem?.updatedAt} />
      </InfoRow>
    </>
  )
}
