import React, { useMemo, useState } from 'react'

import { ApplicationEntity } from 'interfaces/applicationEntities/applicationEntity'
import { RoutesManager } from 'utils/routing/routesManager'
import { useQueryParams } from 'utils/hooks/useQueryParams'
import { FormattedDateRenderer } from 'components/renderers/FormattedDateRenderer'
import { EmptyValueRenderer } from 'components/EmptyValueRenderer'
import { NameAndCodeCellRenderer } from 'components/renderers/NameAndCodeCellRenderer'
import { IssuesCellRenderer } from 'components/renderers/IssuesCellRenderer'
import { HighlightText } from 'components/renderers/HighlightText'
import {
  Button,
  CardProps,
  Chip,
  DataGrid,
  EmptyState,
  Icon,
  ListLayout,
  TableCell,
  TabSegmented,
  TabSegmentedGroup,
  theme,
} from '@ketch-com/deck'
import { Box, Tooltip, Typography, styled } from '@mui/material'
import { clearSearchQuery } from 'store/webTagsListFilterSlice'
import { GridRenderCellParams } from '@mui/x-data-grid-premium'
import { useAppDispatch, useAppSelector } from '../../../../store/hooks'
import { getWebTagsListFilterState } from '../../../../store/webTagsListFilterSlice/selectors'
import { NavigationBreadCrumbs } from 'components/appLayout/appNavigation/breadcrumbs/NavigationBreadCrumbs'
import { useTagsList } from './context/TagsContext'
import { SourceType, TagListState } from './utils/enums'
import { tagListStateLabels } from './utils/labels'
import pluralize from 'pluralize'
import { BulkUpdateTagPurposesModal } from './components/BulkUpdateTagPurposesModal'
import { BulkUpdateTagStatusModal } from './components/BulkUpdateTagStatusModal'
import { TagPurposesMultiSelectChip } from './components/TagPurposesMultiSelectChip'
import { TagStatusUpdateCell } from './components/TagStatusUpdateCell'
import { TagIssueBanners } from './components/TagIssueBanners'
import { UpdateTagsModal } from './components/UpdateTagsModal'
import { NewTagsDrawer } from './components/NewTagsDrawer'
import { WebTagsListFilters } from './components/WebTagsListFilters'

const BulkActionsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '16px',
  paddingRight: '16px',
  height: '40px',
  background: theme.palette.Black.o8,
  borderRadius: '11px',
})

const BulkActionsLeftBox = styled(Box)({
  display: 'flex',
  gap: '24px',
})

export const WebTagsList: React.FC = () => {
  const {
    tagListState,
    setTagListState,
    selectedTagIds,
    setSelectedTagIds,
    bulkUpdateTagIds,
    setBulkUpdateTagIds,
    isLoading,
    isFetching,
    isTagsWriter,
    webTags,
    logoUrls,
    tagsSummaryData,
    pagination,
  } = useTagsList()

  const { queries } = useQueryParams<{
    q?: string
  }>()
  const { q: reduxSearchQuery } = useAppSelector(getWebTagsListFilterState)
  const [searchString, setSearchString] = useState(() => reduxSearchQuery || '')
  const [showUpdateTagsModal, setShowUpdateTagsModal] = useState(false)
  const [showBulkUpdateTagPurposeModal, setShowBulkUpdateTagPurposeModal] = useState(false)
  const [showBulkUpdateTagStatusModal, setShowBulkUpdateTagStatusModal] = useState(false)
  const [showNewTagsDrawer, setShowNewTagsDrawer] = useState(false)

  const showBulkActions = selectedTagIds.length > 0
  const dispatch = useAppDispatch()

  const breadcrumbs = [
    { title: 'Consent & Rights', link: RoutesManager.deployment.root.getURL() },
    { title: 'Consent', link: RoutesManager.deployment.consentHome.root.getURL() },
    { title: 'Tags', link: RoutesManager.orchestration.webTags.root.getURL() },
  ]

  const columns = useMemo(() => {
    const isIssues = webTags.some(item => !!item.hasIssues)

    return [
      ...(isIssues
        ? [
            {
              field: 'issues',
              headerName: 'Issues',
              width: 35,
              renderCell: (params: GridRenderCellParams) => (
                <TableCell>
                  <IssuesCellRenderer hasIssues={params.row.hasIssues} entityType={ApplicationEntity.WEBTAG} />
                </TableCell>
              ),
              sortable: false,
              renderHeader: () => null,
            },
          ]
        : []),
      {
        field: 'nameAndCode',
        headerName: 'Tag',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell justifyContent="space-between" width="100%">
            <NameAndCodeCellRenderer
              name={<HighlightText parts={queries?.q?.split(' ') || []} text={params.row.name} />}
              code={params.row.code}
            />
            {params.row.paused && (
              <Tooltip title={`This tag is paused in ${params.row?.appName}`} placement="bottom-start">
                <span>
                  <Chip label="Paused" size="small" />
                </span>
              </Tooltip>
            )}
          </TableCell>
        ),
        sortable: false,
        width: 300,
      },
      {
        field: 'source',
        headerName: 'Source Connection',
        renderCell: (params: GridRenderCellParams) => {
          // Find the app (system) logo URL for this tag
          const isPropertyTag = params.row.sourceType === SourceType.Property
          const logoUrl = logoUrls[params.row.appCode]

          const title = isPropertyTag ? params.row?.siteName : params.row?.appInstanceName
          const description = isPropertyTag ? 'Property' : params.row?.appName

          return (
            <TableCell>
              {title || description ? (
                <Box display="flex" flexDirection="column">
                  <Typography>{title}</Typography>
                  <Box display="flex" alignItems="center" gap="4px">
                    {logoUrl && <img src={logoUrl} alt={`${params.row.appCode} logo`} width={20} height={20} />}
                    <Typography variant="footnote" color={theme.palette.Text.Primary}>
                      {description}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <EmptyValueRenderer />
              )}
            </TableCell>
          )
        },
        sortable: false,
        width: 290,
      },
      {
        field: 'purposeDependencies',
        headerName: 'Purposes',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <TagPurposesMultiSelectChip
              tag={params.row}
              purposes={params.row.purposes}
              advancedConsentModeEnabled={params.row.advancedConsentModeEnabled}
            />
          </TableCell>
        ),
        sortable: false,
        width: 220,
      },
      {
        field: 'status',
        headerName: 'Orchestration Status',
        renderCell: (params: GridRenderCellParams) => {
          // Status change for this tag is disabled if:
          // - User does not have the tags writer permission
          // - There are no purposes mapped or Built-in consent checks is enabled
          const disabled =
            !isTagsWriter ||
            !params.row?.purposes ||
            !params.row?.purposes.length ||
            params.row.advancedConsentModeEnabled
          return <TagStatusUpdateCell tag={params.row} disabled={disabled} />
        },
        sortable: false,
        width: 200,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Update',
        renderCell: (params: GridRenderCellParams) => (
          <TableCell>
            <FormattedDateRenderer date={params.row?.metadata?.updatedAt} />
          </TableCell>
        ),
        sortable: false,
        width: 200,
      },
    ]
  }, [queries?.q, webTags, logoUrls, isTagsWriter])

  // Pagination
  const totalPages = pagination.totalPages
  const rowsPerPage = useMemo(
    () => (pagination.totalResults ? Math.ceil(pagination.totalResults / totalPages) : 20),
    [pagination.totalResults, totalPages],
  )
  const paginationModel = useMemo(
    () => ({
      page: pagination.page,
      pageSize: rowsPerPage,
    }),
    [pagination.page, rowsPerPage],
  )

  const tagsSummaryCardProps: CardProps[] = useMemo<CardProps[]>(
    () => [
      {
        cardTitle: tagsSummaryData.totalTags.toString(),
        subTitle: 'Tags',
        primaryIcon: 'OFocusCopy',
        cardVariant: 'stretchWidth',
        isNumerical: true,
      },
      {
        cardTitle: tagsSummaryData.enabledTags.toString(),
        subTitle: 'Consent Enabled',
        primaryIcon: 'OCheckRound2',
        textColor: theme.palette.Success.PrimaryDark,
        backgroundColor: theme.palette.Success.Disabled,
        cardVariant: 'stretchWidth',
        isNumerical: true,
      },
      {
        cardTitle: tagsSummaryData.newTags.toString(),
        subTitle: 'New Tags Found',
        primaryIcon: 'OArrowCRight',
        textColor: theme.palette.Warning.Primary,
        backgroundColor: theme.palette.Warning.Disabled,
        cardVariant: 'stretchWidth',
        isNumerical: true,
        onClick: () => setShowNewTagsDrawer(true),
        sx: { cursor: 'pointer' },
      },
    ],
    [tagsSummaryData],
  )

  const onPaginationModelChange = (paginationModel: any) => {
    pagination.onPageChange(paginationModel.page)
  }

  // Update list state on segment button change
  const handleListStateChange = (event: React.MouseEvent<HTMLElement>, newState: TagListState) => {
    if (newState !== null) {
      setTagListState(newState)
    }
  }

  const handleBulkUpdatePurposeClick = () => {
    // Set the tags IDs which the bulk update purpose modal will use
    setBulkUpdateTagIds(selectedTagIds)
    setShowBulkUpdateTagPurposeModal(true)
  }

  const handleBulkChangeStatusClick = () => {
    // Set the tags IDs which the bulk update status modal will use
    setBulkUpdateTagIds(selectedTagIds)
    setShowBulkUpdateTagStatusModal(true)
  }

  const handleClearClick = () => setSelectedTagIds([])

  return (
    <ListLayout
      cardComponentProps={{
        cardItems: tagsSummaryCardProps,
        cardButtonTitle: 'Update Tags',
        onClick: () => setShowUpdateTagsModal(true),
        hidden: !isTagsWriter,
      }}
      actionHeaderBottomBlockComponent={
        <Box width="100%">
          <Box display="flex" justifyContent="space-between" width="100%" gap={3} mb={showBulkActions ? 3 : 0}>
            {/* List filters */}
            <WebTagsListFilters searchString={searchString} setSearchString={setSearchString} isFetching={isFetching} />
            <TabSegmentedGroup
              value={tagListState}
              exclusive
              onChange={handleListStateChange}
              aria-label="Small sizes"
              size="small"
            >
              {Object.values(TagListState).map((listState, index) => (
                <TabSegmented value={listState} key={index}>
                  {tagListStateLabels[listState]}
                </TabSegmented>
              ))}
            </TabSegmentedGroup>
          </Box>

          {/* Error banners */}
          <TagIssueBanners />

          {/* Bulk actions */}
          {showBulkActions && (
            <BulkActionsBox>
              <BulkActionsLeftBox>
                <Typography variant="label">
                  {selectedTagIds.length} {pluralize('tag', selectedTagIds.length)} selected:
                </Typography>
                <Button
                  variant="link"
                  color="secondary"
                  startIcon={<Icon name={'OScales'} />}
                  onClick={handleBulkUpdatePurposeClick}
                >
                  <Typography variant="label">Update Purposes</Typography>
                </Button>
                <Button
                  variant="link"
                  color="secondary"
                  startIcon={<Icon name={'ORefresh'} />}
                  onClick={handleBulkChangeStatusClick}
                >
                  <Typography variant="label">Change Status</Typography>
                </Button>
              </BulkActionsLeftBox>
              <Button variant="link" color="secondary" onClick={handleClearClick}>
                <Typography variant="label">Clear</Typography>
              </Button>
            </BulkActionsBox>
          )}
        </Box>
      }
      sx={{
        // Override the default gap between tabs and content
        '.DeckLayout-container': {
          rowGap: selectedTagIds.length ? '16px !important' : undefined,
          '.MuiBox-root:first-child': {
            rowGap: '0px !important',
          },
        },
      }}
    >
      <NavigationBreadCrumbs type="light" items={breadcrumbs} />
      {!isFetching && !webTags.length ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            backgroundColor: 'white.main',
            padding: 5,
            borderRadius: '11px',
            height: '410px',
          }}
        >
          <EmptyState
            customIcon={<Icon name="OFilter" width="64px" height="64px" />}
            size="large"
            title="No results found"
            subTitle="Sorry, we couldn't find any results that match your filter criteria. Please adjust your filters and try
        again."
            secondaryButtonTitle="Reset to Defaults"
            secondaryButtonProps={{
              onClick: () => {
                dispatch(clearSearchQuery())
                setSearchString('')
                setTagListState(TagListState.All)
              },
            }}
          />
        </Box>
      ) : (
        <DataGrid
          rows={webTags || []}
          columns={columns}
          disableChildrenSorting
          disableColumnMenu
          disableColumnResize
          disableColumnReorder
          disableBorder
          disableAggregation
          disableRowSelectionOnClick={!isTagsWriter}
          hideFooterRowCount
          getRowId={row => row.ID}
          loading={isLoading || pagination.isPending}
          pagination
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={onPaginationModelChange}
          rowSelectionModel={selectedTagIds}
          onRowSelectionModelChange={(selectedIds, _) => setSelectedTagIds(selectedIds as string[])}
          pageSizeOptions={[rowsPerPage]}
          rowCount={pagination.totalResults || rowsPerPage * totalPages}
          checkboxSelection={isTagsWriter}
          hideFooterPagination={totalPages < 2}
          hideFooter={totalPages < 2}
          sx={{
            '& .MuiDataGrid-columnHeader[role="columnheader"]:hover': {
              backgroundImage: 'unset',
            },
            // The following disables the background for selected items and fixes borders
            '.Mui-selected': {
              backgroundImage: 'none !important',
            },
            '.MuiDataGrid-row:not(:last-child)': {
              borderBottom: `1px solid ${theme.palette.Common.Divider}`,
              borderRadius: '0px !important',
            },
            '.MuiDataGrid-row': {
              borderTopLeftRadius: '0px !important',
              borderTopRightRadius: '0px !important',
            },
            '.MuiDataGrid-row::before': {
              height: '0px !important',
            },
            height: 'auto',
          }}
        />
      )}

      {/* Update tags modal */}
      {showUpdateTagsModal && <UpdateTagsModal onClose={() => setShowUpdateTagsModal(false)} />}

      {/* Tag status bulk update modal */}
      {showBulkUpdateTagPurposeModal && (
        <BulkUpdateTagPurposesModal
          tagIds={bulkUpdateTagIds}
          webTags={webTags}
          onClose={() => setShowBulkUpdateTagPurposeModal(false)}
        />
      )}

      {/* Tag purposes bulk update modal */}
      {showBulkUpdateTagStatusModal && (
        <BulkUpdateTagStatusModal
          tagIds={bulkUpdateTagIds}
          webTags={webTags}
          onClose={() => setShowBulkUpdateTagStatusModal(false)}
        />
      )}

      {/* New tags found drawer */}
      <NewTagsDrawer open={showNewTagsDrawer} onClose={() => setShowNewTagsDrawer(false)} />
    </ListLayout>
  )
}
