import React, { useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useQueryClient } from 'react-query'
import { PERMISSIONS } from 'interfaces/permissions/permissions'
import { ENTITLEMENTS } from 'interfaces/entitlements/entitlements'
import { RoutesManager } from 'utils/routing/routesManager'
import { NEW_ITEM_ID } from 'utils/constants/misc'
import { useIsEntitled } from 'utils/hooks/useIsEntitled'
import { useIsPermitted } from 'utils/hooks/useIsPermitted'
import { DeleteAppModal } from 'pages/dataSystems/components/Modals'
import { ApiQueryKeys } from 'api/common/queryKeys'
import { InstalledDataSystemV2DTO } from '@ketch-com/figurehead'
import { Box, Tooltip, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import { Button, Icon, ActionSheetWrapper, ActionSheetItem, TooltipButton } from '@ketch-com/deck'

type Props = {
  appDescriptor: InstalledDataSystemV2DTO
}

export const AppActions: React.FC<Props> = ({ appDescriptor }) => {
  const { dataSystem, connections } = appDescriptor

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navigate = useNavigate()

  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const queryClient = useQueryClient()

  const { isPermitted } = useIsPermitted()
  const isDataSystemsWrite = isPermitted(PERMISSIONS.DATA_SYSTEMS_WRITE)
  const isIntegrationWrite = isPermitted(PERMISSIONS.INTEGRATIONS_WRITE)

  const { isEntitled, isLoadingEntitlements, isFetchingEntitlements } = useIsEntitled()
  const isEntitledToCreateConnection = isEntitled(
    dataSystem?.systemType === 'Internal'
      ? ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_INTERNAL
      : ENTITLEMENTS.QTY_SYSTEM_INTEGRATIONS_EXTERNAL,
  )

  const filteredCapabilities = dataSystem?.filteredCapabilities ?? []
  const hasCapabilities = !!filteredCapabilities?.length
  const hasConnections = !!connections?.length
  const hasOTConnection = dataSystem?.appCode === 'onetrust' && hasConnections
  const documentationUrl = dataSystem?.documentURLs?.docURL

  useEffect(() => {
    queryClient.invalidateQueries(ApiQueryKeys.entitlements)
  }, [queryClient])

  return (
    <>
      <Box display="flex" gap={1.5} alignItems="center">
        <Tooltip placement="bottom" title={isDataSystemsWrite ? '' : 'You do not have permission to manage systems.'}>
          <span>
            <Button
              size="large"
              disabled={!isDataSystemsWrite}
              variant="icon"
              onClick={handleClickListItem}
              color="tertiary"
            >
              <Icon name="OMore" />
            </Button>
          </span>
        </Tooltip>
        <ActionSheetWrapper anchorEl={anchorEl} open={open} onClose={handleClose} transitionDuration={0}>
          {Boolean(documentationUrl) ? (
            <Link
              color="inherit"
              component={RouterLink}
              rel="noopener noreferrer"
              target="_blank"
              to={documentationUrl!}
              underline="none"
            >
              <ActionSheetItem icon="OShare">Documentation</ActionSheetItem>
            </Link>
          ) : null}
          <ActionSheetItem
            disabled={hasConnections}
            onClick={() => {
              handleClose()
              queryClient.refetchQueries([ApiQueryKeys.appsInstanceUsages], { active: true })
              setIsDeleteOpen(true)
            }}
          >
            Delete
          </ActionSheetItem>
        </ActionSheetWrapper>
        {dataSystem?.appCode && (
          <TooltipButton
            //@ts-expect-error
            title={
              !isIntegrationWrite || (!connections?.length && !isEntitledToCreateConnection) || !hasCapabilities ? (
                <>
                  {!isIntegrationWrite ? (
                    <Typography>You do not have permission to manage connections.</Typography>
                  ) : !connections?.length && !isEntitledToCreateConnection ? (
                    <Typography>
                      No application entitlements remaining, please delete a connection or contact support to license
                      additional.
                    </Typography>
                  ) : !hasCapabilities ? (
                    <Typography>
                      This app requires additional capabilities - please contact support@ketch.com to resolve.
                    </Typography>
                  ) : undefined}
                </>
              ) : undefined
            }
            disabled={
              !isIntegrationWrite ||
              (!connections?.length && !isEntitledToCreateConnection) ||
              !hasCapabilities ||
              hasOTConnection ||
              isLoadingEntitlements ||
              isFetchingEntitlements
            }
            size="large"
            color="primary"
            onClick={() =>
              navigate(
                RoutesManager.systems.connections.upsert.root.getURL({
                  id: appDescriptor?.id,
                  instanceId: NEW_ITEM_ID,
                }),
              )
            }
          >
            Connect
          </TooltipButton>
        )}
      </Box>

      {isDeleteOpen && (
        <DeleteAppModal
          appId={appDescriptor?.id || ''}
          onCancel={() => {
            setIsDeleteOpen(false)
          }}
        />
      )}
    </>
  )
}
