import { PersonalDataTypeOptions, TypeListFilterOptions } from 'pages/dataSystems/DataSystem/types'
import { FiltersData } from './SystemListFilter'

export const initialFiltersData: FiltersData = {
  // dynamically generated
  ownerUserIDs: [],
  personalDataType: Object.keys(PersonalDataTypeOptions).map(option => ({
    name: PersonalDataTypeOptions[option].title as string,
    isSelected: false,
    id: option,
  })),
  //dynamically generated
  assignedCategories: [],
  type: TypeListFilterOptions.map(option => ({
    name: option.title,
    isSelected: false,
    id: `${option.value}`,
  })),

  // unsupported
  // dataResidencies: [
  // { name: 'Afghanistan', isSelected: false },
  // { name: 'Albania', isSelected: false },
  // { name: 'Algeria', isSelected: false },
  // { name: 'Andorra', isSelected: false },
  // ],

  // unsupported
  // securityMeasures: [
  // { name: 'Encrption at Rest', isSelected: false },
  // { name: 'Encrption in Transit', isSelected: false },
  // { name: 'Principle of Least Privilege', isSelected: false },
  // { name: 'User Roles', isSelected: false },
  // ],
}

export const initialCounts = {
  ownerUserIDs: 0,
  personalDataType: 0,
  type: 0,
  assignedCategories: 0,
  // dataResidencies: 0,
  // securityMeasures: 0,
}

export const filterDisplayNameMap: Record<string, string> = {
  ownerUserIDs: 'Owners',
  personalDataType: 'Data Sensitivity',
  type: 'System Types',
  assignedCategories: 'Data Categories',
  // dataResidencies: 'Data Residencies',
  // securityMeasures: 'Security Measures',
}
