import awsIcon from 'assets/icons/aws.svg'
import azureSqlDbIcon from 'assets/icons/azuresqldb.svg'
import googleCloudIcon from 'assets/icons/googleCloud.svg'
import azureIcon from 'assets/icons/azure.svg'
import privateCloudIcon from 'assets/icons/privateCloud.svg'
import snowflakeIcon from 'assets/icons/snowflake.svg'
import oracledbIcon from 'assets/icons/oracledb.svg'
import postgresqlIcon from 'assets/icons/postgres.svg'
import mysqlIcon from 'assets/icons/mysql.svg'
import redshiftIcon from 'assets/icons/redshift.svg'
import mongodbIcon from 'assets/icons/mongodb.svg'
import mariaDbIcon from 'assets/icons/mariadb.svg'
import oracleIcon from 'assets/icons/oracle.svg'
import googleCloudDatastoreIcon from 'assets/icons/google-cloud-datastore-icon.svg'
import googleCloudStorageIcon from 'assets/icons/google-cloud-storage-icon.svg'
import sqlserverIcon from 'assets/icons/sqlserver.svg'
import bigQueryIcon from 'assets/icons/bigQuery.svg'
import cockroachDBIcon from 'assets/icons/cockroachdb.svg'
import fallbackIcon from 'assets/icons/unknown-resource-icon.svg'
import databricksIcon from 'assets/icons/databricks.svg'
import dynamodbIcon from 'assets/icons/dynamodb.svg'
import googleWorkspaceIcon from 'assets/icons/googleworkspace.svg'
import salesforceSalesCloudIcon from 'assets/icons/salesforcesalescloud.svg'

export const getAssetCloudImgUrl = (cloud: string) => {
  switch (cloud ? cloud.toLowerCase() : '') {
    case 'aws':
      return awsIcon
    case 'azure sql database':
      return azureSqlDbIcon
    case 'googlecloud':
      return googleCloudIcon
    case 'google cloud storage':
      return googleCloudStorageIcon
    case 'google cloud datastore':
      return googleCloudDatastoreIcon
    case 'azure':
      return azureIcon
    case 'snowflake':
      return snowflakeIcon
    case 'privatecloud':
      return privateCloudIcon
    case 'oracledb':
      return oracledbIcon
    case 'postgresql':
      return postgresqlIcon
    case 'mysql':
      return mysqlIcon
    case 'redshift':
      return redshiftIcon
    case 'mongodb':
      return mongodbIcon
    case 'mariadb':
      return mariaDbIcon
    case 'oracle':
      return oracleIcon
    case 'sql server':
      return sqlserverIcon
    case 'google bigquery':
      return bigQueryIcon
    case 'cockroachdb':
      return cockroachDBIcon
    case 'databricks':
      return databricksIcon
    case 'dynamodb':
      return dynamodbIcon
    case 'google workspace':
      return googleWorkspaceIcon
    case 'salesforce sales cloud':
      return salesforceSalesCloudIcon
    default:
      return fallbackIcon
  }
}
