import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { RoutesManager } from 'utils/routing/routesManager'
import { relativePath } from 'utils/routing/relativePath'
import { SignUpCompleted } from 'pages/onboarding/signUp/components/SignUpCompleted'
import { SignUpCompleteForm } from 'pages/onboarding/signUp/components/SignUpCompleteForm'
import { SignUpForm } from 'pages/onboarding/signUp/components/SignUpForm'

export const SignUpActions: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SignUpForm />} />
      <Route
        path={relativePath(RoutesManager.onboarding.signUp.begin.pattern, RoutesManager.onboarding.signUp.root.pattern)}
        element={<SignUpForm />}
      />
      <Route
        path={relativePath(
          RoutesManager.onboarding.signUp.complete.pattern,
          RoutesManager.onboarding.signUp.root.pattern,
        )}
        element={<SignUpCompleteForm />}
      />
      <Route
        path={relativePath(
          RoutesManager.onboarding.signUp.completed.pattern,
          RoutesManager.onboarding.signUp.root.pattern,
        )}
        element={<SignUpCompleted />}
      />
    </Routes>
  )
}
