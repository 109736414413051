import { Button, Icon, MegaFilter } from '@ketch-com/deck'
import { SystemListFilter } from './SystemListFilter'
import { useSystemListFilterContext } from './SystemListFilterV2Context'

export const SystemListMegaFilter: React.FC = () => {
  const { filterData, isLoading, setFilterData, sections, setCounts, filterButtonText, resetFilters } =
    useSystemListFilterContext()

  return (
    <MegaFilter
      sx={{
        '& .MuiButtonBase-root': {
          alignSelf: 'flex-start',
        },
        '& .MegaFilter-selectedOptions': {
          '& .MuiButtonBase-root': {
            alignSelf: 'center',
          },
        },
      }}
      sections={sections}
      buttonTitle={filterButtonText}
      buttonProps={{
        size: 'medium',
        color: 'secondary',
        endIcon: <Icon name="OArrowCDown" />,
        pending: isLoading,
        sx: {
          '& .DeckBtnWrapChild': {
            whiteSpace: 'nowrap',
          },
        },
      }}
      renderActionItems={
        <Button
          sx={{ marginBlock: 1 }}
          color="tertiary"
          onClick={() => {
            resetFilters()
          }}
        >
          Clear All
        </Button>
      }
      showSelectionCountSection={false}
    >
      {sections.map(section => {
        return (
          <SystemListFilter
            key={section.value}
            name={section.value}
            options={filterData[section.value]}
            setFilters={setFilterData}
            isLoading={isLoading}
            setCount={setCounts}
          />
        )
      })}
    </MegaFilter>
  )
}
